<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";

export default defineComponent({
  name: "CompletedTab",
  components: {
    Table
  },
  data() {
    return {
      table_headers: [
        {
          field: 'order',
          label: 'Order',
          align: 'center'
        },
        {
          field: 'loading_type',
          label: 'Loading Type',
          align: 'center',
          searchType: "select",
          searchOptions: [
            {label: "All", value: ""},
            {label: "Container", value: "container"},
            {label: "Wagon", value: "wagon"},
            {label: "Empty Wagon", value: "wagon_empty"},
          ],
        },
        {
          field: 'applications',
          label: 'Applications',
          align: 'center'
        },
        {
          field: 'company',
          label: 'Company',
          align: 'center'
        },
        {
          field: 'departure',
          label: 'Departure',
          align: 'center'
        },
        {
          field: 'destination',
          label: 'Destination',
          align: 'center'
        },
        {
          field: 'quantity',
          label: 'Quantity',
          align: 'center'
        },
        {
          field: 'weight',
          label: 'Weight',
          align: 'center',
           searchable: false
        },
        {
          field: 'agreed_rate',
          label: 'Agreed Rate',
          align: 'center'
        },
      ]
    }
  }
})
</script>

<template>
  <div>
    <Table
        name="Cancelled Pre-Orders"
        url="/pre_order/list/cancelled/"
        :headers="table_headers"
        :searchable="true"
    >
      <template v-slot:order="{row: order}">
        <span v-if="order.order" class="text-primary">{{ order.order.order_number }}</span>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:applications="{row: order}">
        <div v-if="order.applications" class="text-primary">
          <span class="badge badge-gradient-secondary mx-1"
                v-for="appication in order.applications" :key="`order_application_` + appication.id">
            {{ appication.number }}
          </span>
        </div>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:company="{row: order}">
        <span v-if="order.company" class="text-primary">{{ order.company.name }}</span>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:departure="{row: order}">
        <span v-if="order.departure" class="text-primary">{{ order.departure.name }}</span>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:destination="{row: order}">
        <span v-if="order.destination" class="text-primary">{{ order.destination.name }}</span>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:quantity="{row: order}">
        <span v-if="order.quantity" class="text-primary">{{ order.quantity }}</span>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:weight="{row: order}">
        <span v-if="order.weight" class="text-primary">{{ order.weight }}</span>
        <span v-else class="text-primary">--</span>
      </template>
      <template v-slot:agreed_rate="{row: order}">
        <span v-if="order.agreed_rate" class="text-primary">${{ order.agreed_rate }}</span>
        <span v-else class="text-primary">--</span>
      </template>
    </Table>
  </div>
</template>

<style scoped>

</style>